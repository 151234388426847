import { Button, Modal, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { clsx } from "@/utils";

interface ConfirmRemoveVerificationModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose: () => void;
}

export const ConfirmRemoveVerificationModal = ({ className, open, onClose, ...props }: ConfirmRemoveVerificationModalProps) => {
  const operator = useOperator();

  return (
    <Modal open={open} onClose={onClose} className="max-w-[514px] !p-5">
      <div className={clsx("relative", className)} {...props}>
        <div className="flex max-w-lg flex-col ">
          <div className="mt-3 flex flex-row items-start">
            <Typography variant="title" className="flex-1">
              Confirm Verification Removal
            </Typography>
          </div>
          <div className="mt-3 ">
            <Typography variant="paragraph">
              Are you sure you want to remove the verification? This action will make the vehicle unverified, but it will remain active and
              continue to receive jobs from {operator.name}
            </Typography>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button variant="secondary" onClick={onClose} size="md">
            Cancel
          </Button>
          <Button variant="primary" onClick={onClose} className="border-danger bg-danger hover:bg-danger-dark" size="md">
            Remove Verification
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { Label, Radio, RadioGroup } from "@headlessui/react";
import { isEmpty } from "ramda";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, DropdownSelectOption, ErrorMessage, Icon, TextInput, Tooltip, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { clsx } from "@/utils";
import { colors } from "../../fixtures";
import { EditUnverifiedVehicleFormData } from "../form";

export const VehicleRegistration = () => {
  const {
    register,
    control,
    formState: { errors },
    watch,
  } = useFormContext<EditUnverifiedVehicleFormData>();
  const { country } = useOperator();

  const { isFetching, data: response } = useGetCountryStatesQuery(country);

  const stateOptions = useMemo(
    () => response?.data.states.map((state) => ({ name: state.name, value: state.id })) ?? [],
    [response?.data.states]
  );

  const color = watch("registration.color");

  return (
    <div className="grid grid-cols-2 gap-5 gap-y-6">
      <label htmlFor="registration.number" className="flex flex-col">
        <Typography className="leading-[2rem]">Number Plate</Typography>
        <TextInput {...register("registration.number")} id="registration.number" type="text" placeholder="000-0000" />
        <ErrorMessage errors={errors} name="registration" message={errors.registration?.number?.message} />
      </label>
      <label htmlFor="registration.year" className="flex flex-col">
        <Typography className="leading-[2rem]">Vehicle Year</Typography>
        <Controller
          control={control}
          name="registration.year"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              placeholder="Select Vehicle Year"
              options={Array.from({ length: new Date().getFullYear() - 2000 }, (_, i) => (i + 2000 + 1).toString())}
              value={field.value?.toString()}
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
        <ErrorMessage errors={errors} name="registration" message={errors.registration?.year?.message} />
      </label>
      <label htmlFor="registration.vin" className="flex flex-col">
        <Typography className="leading-[2rem]">VIN</Typography>
        <TextInput {...register("registration.vin")} id="registration.vin" type="text" placeholder="Enter VIN" />
        <ErrorMessage errors={errors} name="registration" message={errors.registration?.vin?.message} />
      </label>
      <label htmlFor="registration.state" className="flex flex-col">
        <Typography className="leading-[2rem]">State</Typography>
        <Controller
          control={control}
          name="registration.state"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              placeholder="Select State"
              isNullable
              options={stateOptions}
              value={field.value}
              onChange={(value: DropdownSelectOption["value"]) => {
                field.onChange(value);
              }}
              hasError={!!errors.registration?.state}
              disabled={isEmpty(stateOptions) || isFetching}
            />
          )}
        />
      </label>
      <label htmlFor="details" className="flex flex-col">
        <Typography variant="paragraph">Vehicle Color</Typography>
        <Controller
          control={control}
          name="registration.color"
          render={({ field }) => (
            <RadioGroup {...field}>
              <div className="mt-2 flex gap-2.5">
                {colors.map((c) => (
                  <Tooltip key={c.name} content={c.name} placement="top">
                    <Radio
                      value={c.name}
                      className={({ checked }) =>
                        clsx(
                          "relative flex h-14 w-14 cursor-pointer items-center justify-center rounded-full p-1.5 focus:outline-none focus:ring-0",
                          { "border border-black": checked }
                        )
                      }
                    >
                      <Label as="span" className="sr-only">
                        {c.name}
                      </Label>
                      <span aria-hidden="true" className={clsx(c.bgColor, "h-11 w-11 flex-shrink-0 rounded-full")} />
                      <Icon isCustom name="check" size="sm" className={clsx("absolute text-white", { hidden: color !== c.name })} />
                    </Radio>
                  </Tooltip>
                ))}
              </div>
            </RadioGroup>
          )}
        />
        <ErrorMessage errors={errors} name="registration" message={errors.registration?.color?.message} />
      </label>
    </div>
  );
};

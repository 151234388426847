import { createApi } from "@reduxjs/toolkit/dist/query/react";
import camelcaseKeys, { CamelCaseKeys } from "camelcase-keys";
import { baseQueryWithProviderEndpointFirst } from "@/redux/baseQuery";
import {
  GetVehiclesResponse,
  Vehicle,
  VehicleAccess,
  VehicleCategoryVerification,
  VehicleDocument,
  VehicleHistoryLog,
} from "@/redux/slices/vehicle/types";
import { transformVehicle, transformGetVehiclesResponse } from "./helpers";
import {
  CreateVehicleParams,
  EditUnverifiedVehicleParams,
  EditVerifiedVehicleParams,
  GetVehicleAccessRawResponse,
  GetVehicleCategoryVerificationRawResponse,
  GetVehicleDocumentParams,
  GetVehicleFormOptionsRawResponse,
  GetVehicleHistoryLogsRawResponse,
  GrantVehicleAccessParams,
  RawVehicleAccess,
  RawVehicleDocument,
  UpdateVehicleAccessParams,
  VerifyVehicleParams,
} from "./types";

export const vehicleApi = createApi({
  baseQuery: baseQueryWithProviderEndpointFirst("vehicle"),
  reducerPath: "vehicleApi",
  endpoints: (builder) => ({
    getVehicle: builder.query<Vehicle, string>({
      query: (id) => `/${id}`,
      transformResponse: transformVehicle,
      providesTags: (_, __, id) => [{ type: "Vehicle", id }],
    }),
    getVehicles: builder.query<GetVehiclesResponse, void>({
      query: () => "",
      transformResponse: transformGetVehiclesResponse,
      providesTags: (result) =>
        result ? [...result.vehicles.map(({ uuid }) => ({ type: "Vehicle" as const, uuid })), "Vehicle"] : ["Vehicle"],
    }),
    archiveVehicle: builder.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, id) => [{ type: "Vehicle", id }],
    }),
    createVehicle: builder.mutation<Vehicle, CreateVehicleParams>({
      query: (data) => ({
        url: "",
        method: "POST",
        body: data,
      }),
      transformResponse: transformVehicle,
      invalidatesTags: ["Vehicle"],
    }),
    addAvatar: builder.mutation<{ avatar: string }, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/${id}/avatar`,
        method: "POST",
        body: body,
      }),
    }),
    updateVehicle: builder.mutation<Vehicle, { id: string; data: EditVerifiedVehicleParams | EditUnverifiedVehicleParams }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: transformVehicle,
      invalidatesTags: (_, __, { id }) => [{ type: "Vehicle", id }],
    }),
    verifyVehicle: builder.mutation<Vehicle, VerifyVehicleParams & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/${id}/verify`,
        method: "POST",
        body,
      }),
      transformResponse: transformVehicle,
    }),
    updateVehicleAccess: builder.mutation<Vehicle, UpdateVehicleAccessParams & { id: string }>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: transformVehicle,
      invalidatesTags: (_, __, { id }) => [{ type: "Vehicle", id }, "VehicleAccess"],
    }),
    updateVehicleCategory: builder.mutation<Vehicle, { id: string; categories: string[] }>({
      query: ({ id, categories }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: {
          categories: categories.map((category) => ({ uuid: category })),
        },
      }),
      transformResponse: transformVehicle,
      invalidatesTags: (_, __, { id }) => [{ type: "Vehicle", id }],
    }),
    getVehicleFormOptions: builder.query<CamelCaseKeys<GetVehicleFormOptionsRawResponse, true>, void>({
      query: () => `/options`,
      transformResponse: (response: GetVehicleFormOptionsRawResponse) => camelcaseKeys(response, { deep: true }),
    }),
    getVehicleDocument: builder.query<VehicleDocument, GetVehicleDocumentParams>({
      query: ({ vehicleId, vehicleDocumentId }) => ({
        url: `/${vehicleId}/document/${vehicleDocumentId}`,
      }),
      transformResponse: (response: RawVehicleDocument) => camelcaseKeys(response, { deep: true }),
      providesTags: ["VehicleDocument"],
    }),
    addVehicleDocument: builder.mutation<VehicleDocument, { id: string; body: FormData }>({
      query: ({ id, body }) => ({
        url: `/${id}/document`,
        method: "POST",
        body,
      }),
      transformResponse: (response: RawVehicleDocument) => camelcaseKeys(response, { deep: true }),
      invalidatesTags: (_, __, { id }) => [{ type: "VehicleDocument", id }],
    }),
    removeVehicleDocument: builder.mutation<void, { vehicleId: string; documentId: string }>({
      query: ({ vehicleId, documentId }) => ({
        url: `/${vehicleId}/document/${documentId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { vehicleId }) => [{ type: "VehicleDocument", id: vehicleId }],
    }),
    getVehicleAccess: builder.query<VehicleAccess[], string>({
      query: (id) => `/${id}/access`,
      transformResponse: (response: GetVehicleAccessRawResponse) =>
        response._embedded.access.map((access) => camelcaseKeys(access, { deep: true })),
      providesTags: (_, __, id) => [{ type: "VehicleAccess", id }],
    }),
    grantVehicleAccess: builder.mutation<VehicleAccess, { vehicleId: string } & GrantVehicleAccessParams>({
      query: ({ vehicleId, ...body }) => ({
        url: `/${vehicleId}/access`,
        method: "POST",
        body,
      }),
      transformResponse: (response: RawVehicleAccess) => camelcaseKeys(response, { deep: true }),
      invalidatesTags: (_, __, { vehicleId }) => [{ type: "VehicleAccess", id: vehicleId }],
    }),
    removeVehicleAccess: builder.mutation<void, { vehicleId: string; accessId: string }>({
      query: ({ vehicleId, accessId }) => ({
        url: `/${vehicleId}/access/${accessId}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, __, { vehicleId }) => [{ type: "VehicleAccess", id: vehicleId }],
    }),
    getHistoryLogs: builder.query<VehicleHistoryLog[], string>({
      query: (id) => `/${id}/history`,
      transformResponse: (response: GetVehicleHistoryLogsRawResponse) =>
        response._embedded.history.map((log) => camelcaseKeys(log, { deep: true })),
      providesTags: ["VehicleHistoryLogs"],
    }),
    getCategoryVerification: builder.query<VehicleCategoryVerification[], string>({
      query: (id) => `/${id}/category-verification`,
      transformResponse: (res: GetVehicleCategoryVerificationRawResponse) => camelcaseKeys(res._embedded.categories, { deep: true }),
    }),
  }),
  tagTypes: ["Vehicle", "VehicleAccess", "VehicleHistoryLogs", "VehicleDocument"],
});

export const {
  useUpdateVehicleMutation,
  useGetVehiclesQuery,
  useArchiveVehicleMutation,
  useCreateVehicleMutation,
  useGetVehicleQuery,
  useAddAvatarMutation,
  useGetVehicleFormOptionsQuery,
  useLazyGetVehicleDocumentQuery,
  useGetVehicleDocumentQuery,
  useAddVehicleDocumentMutation,
  useRemoveVehicleDocumentMutation,
  useGetVehicleAccessQuery,
  useGetHistoryLogsQuery,
  useRemoveVehicleAccessMutation,
  useUpdateVehicleAccessMutation,
  useUpdateVehicleCategoryMutation,
  useGrantVehicleAccessMutation,
  useVerifyVehicleMutation,
  useGetCategoryVerificationQuery,
} = vehicleApi;

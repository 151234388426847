import { differenceInWeeks, format } from "date-fns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, IconButton, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { getRegistrationStatus } from "../../MyVehicles/helpers";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const RegistrationExpiryDetailCard = () => {
  const { dateFormat } = useProfile();
  const { vehicle } = useViewVehicleContext();
  const { metadata } = vehicle;

  const status = getRegistrationStatus(vehicle);

  const tooltip = useMemo(() => {
    const expiringInWeeks = metadata.verifiedExpiry ? differenceInWeeks(new Date(metadata.verifiedExpiry), new Date()) : undefined;

    switch (status) {
      case "expiring":
        return (
          <Typography className="leading-tight">
            Vehicle registration expiring in {expiringInWeeks} weeks. <br /> Kindly update the registration date
          </Typography>
        );
      case "expired":
        return (
          <Typography className="leading-tight">
            Vehicle registration expired.
            <br /> This vehicle is now blocked from
            <br /> accepting network jobs
          </Typography>
        );

      case "verified":
      default:
        return undefined;
    }
  }, [metadata.verifiedExpiry, status]);

  return (
    <VehicleDetailCard
      icon={<Icon name="Calendar" size="xl" className="text-neutral-black" />}
      description={metadata.verifiedExpiry ? `Expires at: ${format(new Date(metadata.verifiedExpiry), dateFormat)}` : "N/A"}
      title={
        <>
          Registration Expiry
          <StatusBadge status={status} />
        </>
      }
      tooltip={tooltip}
      status={status}
      action={<ActionButton status={status} />}
    />
  );
};

const StatusBadge = ({ status }: { status: string }) => {
  switch (status) {
    case "verified":
      return <Icon name="Verify" className="text-info" variant="Bold" size="md" />;
    case "expiring":
      return <Icon name="Danger" size="md" className="text-warning" variant="Bold" />;
    case "expired":
      return <Icon name="InfoCircle" size="md" className=" text-danger" variant="Bold" />;
    default:
      return null;
  }
};

const ActionButton = ({ status }: { status: string }) => {
  const navigate = useNavigate();

  switch (status) {
    case "expiring":
      return <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />;
    case "expired":
      return <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />;
    default:
      return null;
  }
};

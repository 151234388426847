import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Panel, Tooltip, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
// import { useOperator } from "@/hooks";
// import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
// import { useCreateVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { getAssetUrl } from "@/utils";
import { useAddVehicleContext } from "./context";
import { FormData } from "./form";
import { AutoVerifyModal } from "./modals/AutoVerifyModal";
import { ManualVerifyModal } from "./modals/ManualVerifyModal";

export const SelectVerification = () => {
  const navigate = useNavigate();
  const { name } = useOperator();
  const { setStep, step } = useAddVehicleContext();
  const { watch } = useFormContext<FormData>();
  const model = watch("model");
  const make = watch("make");

  const handleSuccess = (vehicleId: string) => {
    navigate(`../${vehicleId}/edit`);
    setStep("select-verification");
  };

  return (
    <Panel className="flex max-w-[600px] flex-col items-center p-7">
      <img
        src={getAssetUrl(make.avatar, {
          width: 112,
          height: 112,
          fit: "contain",
          background: "transparent",
        })}
        alt={model.name}
        className="h-14 w-14 object-contain"
      />
      <Typography variant="title" className="leading-8">
        {make.name} {model.name}
      </Typography>
      <Typography className="mb-5">
        Your {make.name} {model.name} has been identified by the system as a:
      </Typography>
      <VehicleClass vehicleClass={model.class} />

      <div className="mt-2 flex w-full gap-2 rounded-lg bg-info-light px-4 py-3">
        <Icon name="Verify" size="lg" variant="Bold" className="text-info" />
        <Typography variant="action" className="text-info">
          Verified vehicles can accept jobs from the RideMinder network
        </Typography>
      </div>

      <div className="mt-2 flex w-full gap-2 rounded-lg bg-neutral-gray px-4 py-3">
        <Icon name="CloseCircle" size="lg" variant="Bold" className="text-neutral-dark-gray" />
        <Typography variant="action" className="text-neutral-dark-gray">
          Unverified vehicles can only accept jobs from {name}
        </Typography>
      </div>

      <div className="mt-7 flex flex-col items-center gap-3">
        <Button variant="primary" size="lg" onClick={() => setStep("auto-verification")}>
          Yes, I want my vehicle to be verified
        </Button>
        <Button variant="tertiary" size="lg" onClick={() => setStep("manual-verification")}>
          No, I do not want my vehicle to be verified.
        </Button>
      </div>
      <AutoVerifyModal
        modelId={model.id}
        modelMake={`${make.name} ${model.name}`}
        onSuccess={handleSuccess}
        open={step === "auto-verification"}
        setOpen={() => setStep("select-verification")}
      />
      <ManualVerifyModal
        modelId={model.id}
        modelMake={`${make.name} ${model.name}`}
        onSuccess={handleSuccess}
        open={step === "manual-verification"}
        setOpen={() => setStep("select-verification")}
      />
    </Panel>
  );
};

const VehicleClass = ({ vehicleClass }: { vehicleClass: string }) => {
  return (
    <div className="flex w-full items-center justify-center gap-3 rounded-lg border border-neutral-mid-gray bg-neutral-surface-gray px-12 py-6">
      <Icon name="Car" size="xl" />
      <Typography variant="h2" className="capitalize">
        {vehicleClass.toLowerCase()}
      </Typography>
      <Tooltip
        content={
          <div className="max-w-[295px] font-medium">
            This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
            allocations. <br />
            <br />
            <a
              href="https://rideminder.freshdesk.com/en/support/solutions/articles/51000423941-vehicle-eligibility"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              {" "}
              Learn more about Network Eligibility
            </a>
          </div>
        }
        placement="top"
      >
        <Icon name="InfoCircle" variant="Bold" size="md" className="relative z-10 rotate-180 text-neutral-dark-gray" />
      </Tooltip>
    </div>
  );
};

import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Loading, Typography } from "@/components/atoms";
import { useSearchVehicleModelQuery } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { capitalize, getAssetUrl } from "@/utils";
import { VehicleModelButton } from "./common/VehicleModelButton";
import { useAddVehicleContext } from "./context";
import { FormData } from "./form";
import { ModelNotShowingModal } from "./modals/ModelNotShowingModal";

export const SelectModel = () => {
  const navigate = useNavigate();
  const { setStep, step } = useAddVehicleContext();
  const { setValue, watch } = useFormContext<FormData>();
  const make = watch("make");
  const type = watch("type");
  const { data: response, isLoading } = useSearchVehicleModelQuery(
    {
      make: make.name,
      type: type.toUpperCase(),
    },
    {
      skip: !make || !type,
    }
  );

  const selectModel = (id: string, model: string, modelClass: string) => {
    setValue("model", {
      id,
      name: model,
      class: modelClass,
    });
    setStep("check-eligibility");
  };

  const onNotify = () => {
    navigate("../../../my-vehicles");
  };

  const models = useMemo(() => response?.models || [], [response]);

  return (
    <>
      <div className="flex flex-col items-center">
        <Typography variant="title" className="flex items-center justify-center gap-1 leading-8">
          <img
            src={getAssetUrl(make.avatar, {
              width: 56,
              height: 56,
              fit: "contain",
              background: "transparent",
            })}
            alt={make.name}
            className="h-7 w-7 object-contain"
          />{" "}
          Select {capitalize(make.name)} Model
        </Typography>

        <div className="thin-scrollbar relative mt-5 min-h-[200px] w-[500px] overflow-y-auto ">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="flex flex-col justify-center gap-2 p-2">
                {models.map((model, idx) => (
                  <VehicleModelButton key={idx} name={model.model} onClick={() => selectModel(model.id, model.model, model.class)} />
                ))}
                <VehicleModelButton name="Model not showing" onClick={() => setStep("model-not-showing")} />
              </div>
            </>
          )}
        </div>
        <div className="mt-12 flex justify-center">
          <Button variant="tertiary" size="sm" onClick={() => setStep("select-type")}>
            Back to Vehicle Type
          </Button>
        </div>
      </div>
      <ModelNotShowingModal open={step === "model-not-showing"} setOpen={() => setStep("select-model")} onNotify={onNotify} />
    </>
  );
};

export const colors = [
  { name: "Black", bgColor: "bg-black" },
  { name: "White", bgColor: "bg-neutral-gray" },
  { name: "Silver", bgColor: "bg-[#C0C0C0]" },
  { name: "Grey", bgColor: "bg-[#808080]" },
  { name: "Beige", bgColor: "bg-[#F5F5DC]" },
  { name: "Red", bgColor: "bg-[#FF0000]" },
  { name: "Blue", bgColor: "bg-[#0000FF]" },
  { name: "Green", bgColor: "bg-[#008000]" },
  { name: "Yellow", bgColor: "bg-[#FFD700]" },
];

import ReactStars from "react-stars";

export interface StarsProps {
  value?: number;
  edit?: boolean;
  size?: number;
  color1?: string;
  onChange?: (value: number) => void;
  className?: string;
  half?: boolean;
}

export const Stars = (starsProps: StarsProps) => {
  const { value, edit, size, color1, onChange, className, half = false } = starsProps;

  return (
    <ReactStars
      value={value}
      edit={edit}
      size={size}
      count={5}
      half={half}
      color1={color1}
      color2="#FACA3A"
      className={className}
      onChange={onChange}
    />
  );
};

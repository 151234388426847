import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from "react";
import { Icon, IconButton } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useGetHistoryLogsQuery } from "@/redux/apis/vehicle/vehicleApi";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const HistoryLogsDetailCard = () => {
  const {
    vehicle: { uuid },
    setActiveModal,
  } = useViewVehicleContext();
  const { dateFormat } = useProfile();

  const { data: response, isFetching } = useGetHistoryLogsQuery(uuid);

  const logs = useMemo(() => {
    if (!response) return [];

    return response;
  }, [response]);

  const lastUpdated = logs.length > 0 ? logs[0].eventTime : undefined;

  const handleClick = () => {
    setActiveModal("history-logs");
  };

  const ActionButton = () => {
    return (
      <IconButton iconName="ArrowRight2" className="p-1.5 text-neutral-black" iconSize="lg" variant="tertiary" onClick={handleClick} />
    );
  };

  return (
    <VehicleDetailCard
      icon={<Icon name="Clock" size="xl" className="text-neutral-black" />}
      title="History Logs"
      description={`Last updated: ${
        isFetching || !lastUpdated ? "-" : `${formatInTimeZone(new Date(lastUpdated.timestamp), lastUpdated?.timezone, dateFormat)}`
      }`}
      action={<ActionButton />}
    />
  );
};

import { z } from "zod";

const registrationFields = z.object({
  number: z
    .string({
      message: "Number plate is required",
    })
    .min(1, "Number plate is required"),
  year: z
    .string({
      message: "Vehicle year is required",
    })
    .optional(),
  vin: z
    .string({
      message: "Vehicle VIN is required",
    })
    .length(17, "The input must be 17 characters long"),
  state: z.string({
    message: "Vehicle state is required",
  }),
  color: z
    .string({
      message: "Vehicle color is required",
    })
    .min(1, "Vehicle color is required"),
});

const detailsFields = z.object({
  categories: z.array(z.string()),
  capacity: z.number(),
  bags: z.number(),
});

const otherFields = {
  avatar: z.string().nullable(),
  vehicleOwnerFee: z.number().min(0).max(100),
  nickname: z.string().optional(),
};

const verifiedVehicleDocumentFields = {
  insuranceDoc: z.string({
    message: "Insurance document is required",
  }),
  insuranceExpiry: z.date().nullable(),
  insuranceAutoverified: z.boolean().optional(),
  insuranceExpiryConfirm: z.boolean().optional(),
  accreditationDoc: z.string().nullable(),
  accreditationExpiry: z.date().nullable(),
  accreditationAutoverified: z.boolean().optional(),
  accreditationExpiryConfirm: z.boolean().optional(),
};

const unverifiedVehicleDocumentFields = {
  registrationDoc: z.string({
    message: "Registration document is required",
  }),
  registrationExpiry: z.date().nullable(),
  registrationAutoverified: z.boolean().optional(),
  registrationExpiryConfirm: z.boolean().optional(),
  ...verifiedVehicleDocumentFields,
};

export const verifiedSchema = z.object({
  ...otherFields,
  details: detailsFields,
  documents: z
    .object({
      ...verifiedVehicleDocumentFields,
    })
    .refine(
      (data) => {
        if (data.insuranceDoc) return data.insuranceAutoverified ? true : !!data.insuranceExpiry && data.insuranceExpiryConfirm;
        return true;
      },
      {
        message: "Please confirm insurance expiry date",
        path: ["insuranceExpiry"],
      }
    )
    .refine(
      (data) => {
        if (data.accreditationDoc)
          return data.accreditationAutoverified ? true : !!data.accreditationExpiry && data.accreditationExpiryConfirm;
        return true;
      },
      {
        message: "Please confirm accreditation expiry date",
        path: ["accreditationExpiry"],
      }
    ),
});

export const unverifiedSchema = z.object({
  ...otherFields,
  details: detailsFields,
  registration: registrationFields,
  documents: z
    .object({
      ...verifiedVehicleDocumentFields,
      ...unverifiedVehicleDocumentFields,
    })
    .refine(
      (data) => {
        if (data.registrationDoc) return data.registrationAutoverified ? true : !!data.registrationExpiry && data.registrationExpiryConfirm;
        return true;
      },
      {
        message: "Please confirm registration expiry date",
        path: ["registrationExpiry"],
      }
    )
    .refine(
      (data) => {
        if (data.insuranceDoc) return data.insuranceAutoverified ? true : !!data.insuranceExpiry && data.insuranceExpiryConfirm;
        return true;
      },
      {
        message: "Please confirm insurance expiry date",
        path: ["insuranceExpiry"],
      }
    )
    .refine(
      (data) => {
        if (data.accreditationDoc)
          return data.accreditationAutoverified ? true : !!data.accreditationExpiry && data.accreditationExpiryConfirm;
        return true;
      },
      {
        message: "Please confirm accreditation expiry date",
        path: ["accreditationExpiry"],
      }
    ),
});

export type EditVerifiedVehicleFormData = z.infer<typeof verifiedSchema>;
export type EditUnverifiedVehicleFormData = z.infer<typeof unverifiedSchema>;

import { format } from "date-fns";
import { useState } from "react";
import { Button, Icon, Panel, Tooltip, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { clsx, getAssetUrl } from "@/utils";
import { getCountryFlagSrc } from "@/utils/getCountryFlag";
import { VehicleRegistrationStatusBadge } from "./common/VehicleRegistrationStatusBadge";
import { useEditVehicleContext } from "./context";
import { AutoVerifyModal } from "./features/AutoVerify/AutoVerifyModal";
import { UpdateVerificationModal } from "./modals/UpdateVerificationModal";

export const VerificationSidebar = () => {
  const { vehicle } = useEditVehicleContext();

  const {
    model,
    registrationNumber,
    metadata: { isVerified },
  } = vehicle;

  return (
    <Panel className="w-[350px] shrink-0">
      <Typography variant="action">Auto-Filled Vehicle Details</Typography>
      <Typography className="text-neutral-dark-gray">
        {isVerified
          ? "Below are the details automatically filled by the system based on the Number Plate you entered"
          : "Below are the details automatically filled by the system based on your selections"}
      </Typography>

      <div className="mt-3 flex items-center gap-3 rounded-lg bg-neutral-surface-gray px-3 py-3">
        <div className="flex items-center justify-center rounded-full border border-solid border-neutral-mid-gray p-2">
          <img
            src={getAssetUrl(model.avatar, { height: 56, width: 56, background: "transparent", fit: "contain" })}
            className="h-[28px] w-[28px] rounded-full "
          />
        </div>
        <div className="flex flex-1 flex-col">
          <Typography variant="title" className="text-neutral-black">
            {model.make} {model.model}
          </Typography>
          {isVerified && (
            <Typography variant="action" className="flex items-center gap-1 text-neutral-dark-gray">
              Number Plate: {registrationNumber} <VehicleRegistrationStatusBadge vehicle={vehicle} />
            </Typography>
          )}
        </div>
      </div>
      {isVerified ? <VerifiedTable /> : <NonVerifiedTable />}
    </Panel>
  );
};

const NonVerifiedTable = () => {
  const { vehicle } = useEditVehicleContext();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="mt-3 grid grid-cols-2 gap-3">
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center text-neutral-dark-gray">
          Network Eligibility
          <Tooltip
            content={
              <div className="max-w-[295px] font-medium">
                This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
                allocations. <br />
                <br />
                <a
                  href="https://rideminder.freshdesk.com/en/support/solutions/articles/51000423941-vehicle-eligibility"
                  rel="noreferrer"
                  target="_blank"
                  className="underline"
                >
                  {" "}
                  Learn more about Network Eligibility
                </a>
              </div>
            }
            placement="top"
          >
            <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block -rotate-180 text-neutral-dark-gray" size="sm" />
          </Tooltip>
        </Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">-</div>
      <div className="col-span-1 flex items-center">
        <Typography className="flex items-center  text-neutral-dark-gray">Engine Type</Typography>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <Typography className="inline-block capitalize text-neutral-black">{vehicle.model.engine.toLowerCase()}</Typography>
      </div>
      <div className="col-span-2 mt-2">
        <Button variant="primary" size="lg" className="w-full" onClick={() => setOpenModal(true)}>
          Verify for $5.00
        </Button>
      </div>
      <AutoVerifyModal open={openModal} setOpen={setOpenModal} vehicle={vehicle} onSuccess={() => setOpenModal(false)} />
    </div>
  );
};

const VerifiedTable = () => {
  const [step, setStep] = useState<string | undefined>();
  const { vehicle } = useEditVehicleContext();
  const { dateFormat } = useProfile();

  const renderStateFlag = () => {
    const flagSrc = getCountryFlagSrc(vehicle.country.countryCode);

    return <div className="ml-3 flex gap-2 pl-3">{flagSrc && <img src={flagSrc} className="h-6 w-6 rounded-full object-cover" />}</div>;
  };

  const isExpired = vehicle.metadata.verifiedExpiry && new Date(vehicle.metadata.verifiedExpiry) < new Date();

  return (
    <>
      <div className="mt-3 grid grid-cols-2 gap-3">
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">Year</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-block capitalize text-neutral-black">{vehicle.year}</Typography>
        </div>
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">Color</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-block capitalize text-neutral-black">{vehicle.color}</Typography>
        </div>
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">
            Network Eligibility
            <Tooltip
              content={
                <div className="max-w-[295px] font-medium">
                  This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
                  allocations. <br />
                  <br />
                  <a
                    href="https://rideminder.freshdesk.com/en/support/solutions/articles/51000423941-vehicle-eligibility"
                    rel="noreferrer"
                    target="_blank"
                    className="underline"
                  >
                    {" "}
                    Learn more about Network Eligibility
                  </a>
                </div>
              }
              placement="top"
            >
              <Icon name="InfoCircle" variant="Bold" className="ml-1 inline-block -rotate-180 text-neutral-dark-gray" size="sm" />
            </Tooltip>
          </Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-block capitalize text-neutral-black">{vehicle.model.class.toLowerCase()}</Typography>
        </div>
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">VIN number</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-block capitalize text-neutral-black">{vehicle.vin}</Typography>
        </div>
        {vehicle.metadata.verifiedExpiry && (
          <>
            <div className="col-span-1 flex items-center">
              <Typography className="flex items-center text-neutral-dark-gray">Registration Expiry</Typography>
            </div>
            <div className="col-span-1 flex items-center justify-end">
              <Typography className={clsx("inline-flex items-center capitalize", isExpired ? "text-danger" : "text-neutral-black")}>
                {format(new Date(vehicle.metadata.verifiedExpiry), dateFormat)}
                {isExpired && (
                  <Tooltip content="Registration has expired" placement="right">
                    <Icon name="InfoCircle" variant="Bold" className="ml-1 text-danger" size="sm" />
                  </Tooltip>
                )}
              </Typography>
            </div>
          </>
        )}
        <div className="col-span-1 flex items-center">
          <Typography className="flex items-center text-neutral-dark-gray">State</Typography>
        </div>
        <div className="col-span-1 flex items-center justify-end">
          <Typography className="inline-flex gap-1 capitalize text-neutral-black">
            {renderStateFlag()}
            {vehicle.state.abbreviation}
          </Typography>
        </div>
        <div className="col-span-2">
          <Button variant="secondary" onClick={() => setStep("update-verification")} size="lg" className="mt-3 w-full">
            Update Verification
          </Button>
        </div>
      </div>
      <UpdateVerificationModal vehicle={vehicle} open={step === "update-verification"} onClose={() => setStep(undefined)} />
    </>
  );
};

import { BooleanDisplay, Button, Divider, Loading, Typography } from "@/components/atoms";
import { renderDispatchTime } from "@/components/pages/Config/Admin/DispatchSettings/helpers";
import { useLazyGetOffloadingConfigurationQuery } from "@/redux/apis/addOn/addOnApi";
import { OffloadingConfiguration } from "@/redux/slices/addOn/types";
import { useEffect } from "react";

interface OffloadingDetailsProps {
  onEdit: () => void;
  configuration?: OffloadingConfiguration;
}

export const OffloadingDetails = ({ onEdit, configuration }: OffloadingDetailsProps) => {
  const [getOffloadingConfiguration, { isFetching }] = useLazyGetOffloadingConfigurationQuery();

  useEffect(() => {
    getOffloadingConfiguration();
  }, [getOffloadingConfiguration]);

  return (
    <div className="relative flex flex-col w-full bg-neutral-surface-gray rounded-lg p-5 my-4">
      {isFetching && <Loading className="rounded-lg" />}
      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-col">
          <Typography variant="action">Auto Dispatch to Private Network</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">Set the time before pickup</Typography>
        </div>
        <BooleanDisplay status={configuration?.enabledAutomaticDispatchPrivateNetwork}>
          <Typography variant="paragraph">{configuration?.enabledAutomaticDispatchPrivateNetwork ? "Yes" : "No"}</Typography>
        </BooleanDisplay>
      </div>
      <div className="flex flex-row items-center mt-5">
        <div className="flex flex-1 flex-row items-center gap-2">
          <span className="h-2 w-2 shrink-0 grow-0 rounded-full bg-neutral-dark-gray" />
          <Typography variant="action">Standard Hold Time</Typography>
        </div>
        <Typography variant="paragraph">{renderDispatchTime(configuration?.dispatchAutoPrivateNetworkTime, configuration?.dispatchAutoPrivateNetworkTimeframe)}</Typography>
      </div>
      <Divider className="my-6" />
      <div className="flex flex-row items-center">
        <div className="flex flex-1 flex-col">
          <Typography variant="action">Auto Dispatch to Public Network</Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">Set the time before pickup</Typography>
        </div>
        <BooleanDisplay status={configuration?.enabledAutomaticDispatchPublicNetwork}>
          <Typography variant="paragraph">{configuration?.enabledAutomaticDispatchPublicNetwork ? "Yes" : "No"}</Typography>
        </BooleanDisplay>
      </div>
      <div className="flex flex-row items-center mt-5">
        <div className="flex flex-1 flex-row items-center gap-2">
          <span className="h-2 w-2 shrink-0 grow-0 rounded-full bg-neutral-dark-gray" />
          <Typography variant="action">Standard Hold Time</Typography>
        </div>
        <Typography variant="paragraph">{renderDispatchTime(configuration?.dispatchAutoPublicNetworkTime, configuration?.dispatchAutoPublicNetworkTimeframe)}</Typography>
      </div>
      <div className="flex flex-row w-full items-center justify-end mt-8">
        <Button variant="primary" size="sm" onClick={onEdit}>Edit Settings</Button>
      </div>
    </div>
  );
};

import { formatInTimeZone } from "date-fns-tz";
import { Breakdown, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { BookingHistoryActivity } from "@/redux/slices/booking/types";

export const JobHistoryActivityBreakdown = ({ activity }: { activity: BookingHistoryActivity }) => {
  const { fullTimeFormat } = useProfile();

  return (
    <div className="w-full space-y-3 bg-neutral-surface-gray p-5 sm:w-1/2">
      <Typography variant="action">Trip Driver Activity</Typography>
      <Breakdown className="[&_.typography]:leading-tight">
        <Breakdown.Item
          label="Driver On Way to Pickup:"
          value={
            activity?.driverOnWay
              ? formatInTimeZone(new Date(activity.driverOnWay.timestamp), activity.driverOnWay.timezone, fullTimeFormat)
              : "-"
          }
        />
        <Breakdown.Item
          label="Client Picked Up:"
          value={
            activity?.pickedUp ? formatInTimeZone(new Date(activity.pickedUp.timestamp), activity.pickedUp.timezone, fullTimeFormat) : "-"
          }
        />
        <Breakdown.Item
          label="Driver Close to Destination:"
          value={
            activity?.closeToDestination
              ? formatInTimeZone(new Date(activity.closeToDestination.timestamp), activity.closeToDestination.timezone, fullTimeFormat)
              : "-"
          }
        />
        <Breakdown.Item
          label="Client Dropped Off:"
          value={
            activity?.droppedOff
              ? formatInTimeZone(new Date(activity.droppedOff.timestamp), activity.droppedOff.timezone, fullTimeFormat)
              : "-"
          }
        />
        {activity?.cancelled ? (
          <Breakdown.Item
            label="Job Cancel led:"
            value={
              activity?.cancelled
                ? formatInTimeZone(new Date(activity.cancelled.timestamp), activity.cancelled.timezone, fullTimeFormat)
                : "-"
            }
          />
        ) : (
          <Breakdown.Item
            label="Driver Closed Job:"
            value={activity?.closed ? formatInTimeZone(new Date(activity.closed.timestamp), activity.closed.timezone, fullTimeFormat) : "-"}
          />
        )}
      </Breakdown>
    </div>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { EditUnverifiedVehicleParams, EditVerifiedVehicleParams } from "@/redux/apis/vehicle/types";
import { useUpdateVehicleMutation } from "@/redux/apis/vehicle/vehicleApi";
import { addToast } from "@/utils";
import { PageHeader } from "../../PageHeader/PageHeader";
import { VerificationSidebar } from "./VerificationSidebar";
import { EditVehicleProvider, useEditVehicleContext } from "./context";
import { VehicleDetails } from "./fields/VehicleDetails";
import { VehicleImage } from "./fields/VehicleImage";
import { VehicleRegistration } from "./fields/VehicleRegistration";
import { EditUnverifiedVehicleFormData, EditVerifiedVehicleFormData, unverifiedSchema, verifiedSchema } from "./form";

export const EditVehiclePanel = () => {
  return (
    <EditVehicleProvider>
      <PageHeader.Actions />
      <div className="flex items-start gap-3 p-5">
        <Panel className="flex w-full flex-col gap-5">
          <div className="flex flex-col">
            <Typography variant="h3">Vehicle Details</Typography>
            <Typography className="text-neutral-dark-gray">
              Fill up the last few necessary information to complete your vehicle details
            </Typography>
          </div>
          <Forms />
        </Panel>
        <VerificationSidebar />
      </div>
    </EditVehicleProvider>
  );
};

const Forms = () => {
  const { vehicle } = useEditVehicleContext();

  return vehicle.metadata.isVerified ? <VerifiedVehicleEditForm /> : <UnverifiedVehicleEditForm />;
};

const UnverifiedVehicleEditForm = () => {
  const navigate = useNavigate();
  const { vehicle } = useEditVehicleContext();
  const [editVehicle, { isLoading }] = useUpdateVehicleMutation();

  const documents = useMemo(() => {
    const accreditation = vehicle.documents.find((doc) => doc.documentType === "ACCREDITATION");
    const insurance = vehicle.documents.find((doc) => doc.documentType === "INSURANCE");
    const registration = vehicle.documents.find((doc) => doc.documentType === "REGISTRATION");

    return {
      accreditationDoc: accreditation?.uuid ?? null,
      accreditationExpiry: accreditation?.expiryDate ? new Date(accreditation.expiryDate) : null,
      insuranceDoc: insurance?.uuid ?? "",
      insuranceExpiry: insurance?.expiryDate ? new Date(insurance.expiryDate) : null,
      registrationDoc: registration?.uuid ?? "",
      registrationExpiry: registration?.expiryDate ? new Date(registration.expiryDate) : null,
    };
  }, [vehicle]);

  const defaults = useMemo(() => {
    return {
      avatar: vehicle.avatar,
      vehicleOwnerFee: vehicle.maintenanceFee,
      nickname: vehicle.identifier ?? undefined,
      registration: {
        color: vehicle.color ?? "",
        number: vehicle.registrationNumber,
        state: vehicle.state.uuid,
        vin: vehicle.vin,
        year: vehicle.year ? vehicle.year.toString() : undefined,
      },
      details: {
        bags: vehicle.capacity.bags,
        capacity: vehicle.capacity.pax,
        categories: vehicle.categories.map((category) => category.uuid),
      },
      documents: documents,
    };
  }, [documents, vehicle]);

  const form = useForm<EditUnverifiedVehicleFormData>({
    resolver: zodResolver(unverifiedSchema),
    defaultValues: defaults,
    shouldFocusError: true,
    mode: "all",
  });

  const handleSave = async (data: EditUnverifiedVehicleFormData) => {
    const documents: EditUnverifiedVehicleParams["documents"] = [];

    if (data.documents.registrationDoc) {
      documents.push({
        uuid: data.documents.registrationDoc,
        expiry_date: data.documents.registrationExpiry ? format(data.documents.registrationExpiry, "yyyy-MM-dd") : null,
        document_type: "REGISTRATION",
      });
    }

    if (data.documents.insuranceDoc) {
      documents.push({
        uuid: data.documents.insuranceDoc,
        expiry_date: data.documents.insuranceExpiry ? format(data.documents.insuranceExpiry, "yyyy-MM-dd") : null,
        document_type: "INSURANCE",
      });
    }

    if (data.documents.accreditationDoc) {
      documents.push({
        uuid: data.documents.accreditationDoc,
        expiry_date: data.documents.accreditationExpiry ? format(data.documents.accreditationExpiry, "yyyy-MM-dd") : null,
        document_type: "ACCREDITATION",
      });
    }

    editVehicle({
      id: vehicle.uuid,
      data: {
        identifier: data.nickname ?? "",
        state_uuid: data.registration.state,
        registration_number: data.registration.number,
        year: Number(data.registration.year),
        color: data.registration.color ?? undefined,
        capacity_pax: data.details.capacity,
        capacity_bags: data.details.bags,
        vin: data.registration.vin,
        categories: data.details.categories.map((category) => ({ uuid: category })),
        documents: documents,
        maintenance_fee: data.vehicleOwnerFee,
      } satisfies EditUnverifiedVehicleParams,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle successfully updated");
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSave)} id="edit-vehicle-form" className="flex flex-col gap-5">
        <VehicleImage vehicle={vehicle} />
        {!vehicle.metadata.isVerified && <VehicleRegistration />}
        <VehicleDetails vehicle={vehicle} />
        <div className="flex justify-end gap-4">
          <Button onClick={() => navigate("../")} variant="secondary" size="lg" disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="primary" size="lg" type="submit" form="edit-vehicle-form" disabled={isLoading || !form.formState.isDirty}>
            {isLoading ? <Spinner /> : "Save Changes"}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

const VerifiedVehicleEditForm = () => {
  const navigate = useNavigate();
  const { vehicle } = useEditVehicleContext();
  const [editVehicle, { isLoading }] = useUpdateVehicleMutation();

  const documents = useMemo(() => {
    const accreditation = vehicle.documents.find((doc) => doc.documentType === "ACCREDITATION");
    const insurance = vehicle.documents.find((doc) => doc.documentType === "INSURANCE");
    const registration = vehicle.documents.find((doc) => doc.documentType === "REGISTRATION");

    return {
      accreditationDoc: accreditation?.uuid ?? null,
      accreditationExpiry: accreditation?.expiryDate ? new Date(accreditation.expiryDate) : null,
      insuranceDoc: insurance?.uuid ?? "",
      insuranceExpiry: insurance?.expiryDate ? new Date(insurance.expiryDate) : null,
      registrationDoc: registration?.uuid ?? "",
      registrationExpiry: registration?.expiryDate ? new Date(registration.expiryDate) : null,
    };
  }, [vehicle]);

  const defaults = useMemo(() => {
    return {
      avatar: vehicle.avatar,
      vehicleOwnerFee: vehicle.maintenanceFee,
      nickname: vehicle.identifier ?? undefined,
      details: {
        bags: vehicle.capacity.bags,
        capacity: vehicle.capacity.pax,
        categories: vehicle.categories.map((category) => category.uuid),
      },
      documents,
    };
  }, [documents, vehicle]);

  const form = useForm<EditVerifiedVehicleFormData>({
    resolver: zodResolver(verifiedSchema),
    defaultValues: defaults,
    shouldFocusError: true,
    mode: "all",
  });

  const handleSave = async (data: EditVerifiedVehicleFormData) => {
    const documents: EditUnverifiedVehicleParams["documents"] = [];

    if (data.documents.insuranceDoc) {
      documents.push({
        uuid: data.documents.insuranceDoc,
        expiry_date: data.documents.insuranceExpiry ? format(data.documents.insuranceExpiry, "yyyy-MM-dd") : null,
        document_type: "INSURANCE",
      });
    }

    if (data.documents.accreditationDoc) {
      documents.push({
        uuid: data.documents.accreditationDoc,
        expiry_date: data.documents.accreditationExpiry ? format(data.documents.accreditationExpiry, "yyyy-MM-dd") : null,
        document_type: "ACCREDITATION",
      });
    }

    editVehicle({
      id: vehicle.uuid,
      data: {
        identifier: data.nickname ?? "",
        capacity_pax: data.details.capacity,
        capacity_bags: data.details.bags,
        categories: data.details.categories.map((category) => ({ uuid: category })),
        documents,
        maintenance_fee: data.vehicleOwnerFee,
      } satisfies EditVerifiedVehicleParams,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle successfully updated");
      })
      .catch((e) => {
        getErrorMessages(e).forEach((m) => addToast("danger", m));
      });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSave)} id="edit-vehicle-form" className="flex flex-col gap-5">
        <VehicleImage vehicle={vehicle} />
        <VehicleDetails vehicle={vehicle} />
        <div className="flex justify-end gap-4">
          <Button onClick={() => navigate("../")} variant="secondary" size="lg" disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="primary" size="lg" type="submit" form="edit-vehicle-form" disabled={isLoading || !form.formState.isDirty}>
            {isLoading ? <Spinner /> : "Save Changes"}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

import { useCallback } from "react";
import { Dropzone, Icon, IconButton, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyRequestVehicleDocumentQuery } from "@/redux/apis/document/documentApi";
import { useAddVehicleDocumentMutation } from "@/redux/apis/vehicle/vehicleApi";
import { VehicleDocument, VehicleDocumentType } from "@/redux/slices/vehicle/types";
import { addToast, downloadFile } from "@/utils";
import { useEditVehicleContext } from "../context";

interface VehicleDocumentUploadFieldProps {
  document?: VehicleDocument;
  documentType: VehicleDocumentType;
  onUpload: (documentId: string) => void;
  onRemove: () => void;
  hasError: boolean;
}

export const VehicleDocumentUploadField = ({ document, documentType, onUpload, onRemove, hasError }: VehicleDocumentUploadFieldProps) => {
  const {
    vehicle: { uuid: vehicleId },
  } = useEditVehicleContext();
  const [addVehicleDocument, { isLoading: isUploading }] = useAddVehicleDocumentMutation();
  const [requestDocument, { isLoading }] = useLazyRequestVehicleDocumentQuery();

  const handleDownload = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!document) return;

      requestDocument({
        id: document.uuid,
        fileType: "pdf", //todo: update once fixed in API
        vehicleId,
      })
        .unwrap()
        .then((res) => {
          downloadFile(res.filename, document.uuid);
        });
    },
    [document, requestDocument, vehicleId]
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onRemove();
    },
    [onRemove]
  );

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_type", documentType);

    addVehicleDocument({
      id: vehicleId,
      body: formData,
    })
      .unwrap()
      .then((res) => {
        onUpload(res.uuid);
      })
      .catch((e) => {
        getErrorMessages(e).forEach((e) => addToast("danger", e));
      });
  };

  const renderContent = useCallback(() => {
    if (document) {
      const fileExtension = document.filename.split(".").pop();

      return (
        <div className="flex items-center gap-2 rounded-lg bg-neutral-surface-gray px-3 py-2">
          <div className="grow-0">{renderIcon(fileExtension)}</div>
          <Typography variant="action" className="flex-1 truncate text-neutral-black">
            {document.filename}
          </Typography>

          <div className="flex gap-2.5">
            {document.approvalStatus === "PROCESSING" ? (
              <Spinner />
            ) : (
              <>
                <IconButton
                  className="!text-neutral-black"
                  iconName="ImportCurve"
                  variant="custom"
                  iconSize="lg"
                  onClick={handleDownload}
                />
                <IconButton
                  className="!text-neutral-black"
                  iconName="close"
                  variant="custom"
                  iconSize="lg"
                  isCustomIcon
                  onClick={handleRemove}
                />
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="flex flex-col place-items-center p-5">
        <Typography variant="action">
          Select a <u>PDF</u>, <u>PNG</u> or <u>JPG</u> file to upload
        </Typography>
        <Typography variant="paragraph">or drag and drop it here</Typography>
      </div>
    );
  }, [document, handleDownload, handleRemove]);

  return (
    <Dropzone
      accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "application/pdf": [".pdf"] }}
      maxSize={10000000}
      upload={uploadFile}
      className={hasError ? "border-danger" : document ? "border-none" : ""}
      detail={renderContent()}
      disabled={isLoading || isUploading}
    />
  );
};

const renderIcon = (extension?: string) => {
  switch (extension) {
    case "pdf":
      return <Icon name="file-pdf" size="md" isCustom />;
    case "doc":
    case "docx":
      return <Icon name="google-doc" size="md" isCustom />;
    case "jpg":
    case "jpeg":
      return <Icon name="file-jpg" size="md" isCustom />;
    case "png":
      return <Icon name="file-png" size="md" isCustom />;
    default:
      return <Icon name="file" size="md" isCustom />;
  }
};

import { differenceInWeeks, format } from "date-fns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, IconButton, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { useLazyGetVehicleDocumentQuery } from "@/redux/apis/vehicle/vehicleApi";
import { downloadFile } from "@/utils";
import { getInsuranceStatus } from "../../MyVehicles/helpers";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const VehicleInsuranceDetailCard = () => {
  const { dateFormat } = useProfile();
  const { vehicle } = useViewVehicleContext();
  const status = getInsuranceStatus(vehicle);
  const insuranceDoc = vehicle.documents.find((doc) => doc.documentType === "INSURANCE");

  const tooltip = useMemo(() => {
    if (!insuranceDoc || !insuranceDoc.expiryDate) return undefined;

    switch (status) {
      case "expiring": {
        const expiryDate = new Date(insuranceDoc.expiryDate);
        const difference = differenceInWeeks(expiryDate, new Date());

        return (
          <Typography className="leading-tight">
            Vehicle insurance expiring in {difference} weeks. <br /> Kindly update the insurance
          </Typography>
        );
      }

      case "expired":
        return <Typography className="leading-tight">Vehicle insurance expired.</Typography>;

      case "verified":
      default:
        return undefined;
    }
  }, [insuranceDoc, status]);

  return (
    <VehicleDetailCard
      title={
        <>
          Vehicle Insurance
          <StatusBadge status={status} />
        </>
      }
      description={insuranceDoc?.expiryDate ? `Expires at: ${format(new Date(insuranceDoc?.expiryDate), dateFormat)}` : "N/A"}
      tooltip={tooltip}
      icon={<Icon name="file-pdf" isCustom size="xl" className="text-neutral-black" />}
      action={<ActionButton status={status} />}
      status={status}
    />
  );
};

const StatusBadge = ({ status }: { status: string }) => {
  switch (status) {
    case "verified":
      return <Icon name="Verify" className="text-info" variant="Bold" size="md" />;
    case "expiring":
      return <Icon name="Danger" size="md" className="text-warning" variant="Bold" />;
    case "expired":
      return <Icon name="InfoCircle" size="md" className=" text-danger" variant="Bold" />;
    default:
      return null;
  }
};

const ActionButton = ({ status }: { status: string }) => {
  const navigate = useNavigate();

  switch (status) {
    case "expiring":
      return <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />;
    case "expired":
      return <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />;
    case "verified":
      return <DownloadButton />;
    default:
      return null;
  }
};

const DownloadButton = () => {
  const { vehicle } = useViewVehicleContext();
  const [getDocument, { isLoading }] = useLazyGetVehicleDocumentQuery();

  const insuranceDoc = vehicle.documents.find((doc) => doc.documentType === "INSURANCE");

  const handleDownload = () => {
    if (!insuranceDoc) return;

    getDocument({
      vehicleId: vehicle.uuid,
      vehicleDocumentId: insuranceDoc.uuid,
    })
      .unwrap()
      .then((res) => {
        downloadFile(res.filename, res.uuid);
      });
  };

  return (
    <IconButton
      disabled={isLoading}
      iconName="ImportCurve"
      className="p-1.5 text-neutral-black"
      iconSize="lg"
      variant="tertiary"
      onClick={handleDownload}
    />
  );
};

export const getTimeMultiplier = (timeframe: string) => {
  switch (timeframe) {
    case "hour":
      return 60;
    case "day":
      return 60 * 24;
    case "week":
      return 60 * 24 * 7;
    default:
      return 1;
  }
};

export const renderDispatchTime = (time?: string | number, timeFrame?: string) => {
  if (time && timeFrame) {
    return `${time} ${timeFrame}${Number(time) !== 1 ? "s" : ""}`;
  }

  return null;
};
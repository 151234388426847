import { isEmpty } from "ramda";
import { useCallback, useMemo, useState } from "react";
import { Alert, Button, DropdownSelect, Icon, Loading, Modal, Spinner, TextInput, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useVerifyVehicleMutation, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { useAppDispatch } from "@/redux/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast } from "@/utils";

interface AutoVerifyModalProps {
  open: boolean;
  vehicle: Vehicle;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
}

export const AutoVerifyModal = ({ open, setOpen, vehicle }: AutoVerifyModalProps) => {
  const dispatch = useAppDispatch();
  const { country } = useOperator();
  const { isFetching, data: response } = useGetCountryStatesQuery(country);
  const [verifyVehicle, { isLoading, error }] = useVerifyVehicleMutation();
  const [numberPlate, setNumberPlate] = useState<string>(vehicle.registrationNumber);
  const [state, setState] = useState<string>("");

  const modelMake = `${vehicle.model.make} ${vehicle.model.model}`;

  const options = useMemo(
    () => response?.data.states.map((state) => ({ name: state.name, value: state.id })) ?? [],
    [response?.data.states]
  );

  const renderAlert = useCallback(() => {
    return !!error && <Alert className="mt-4" type="danger" message="Verification failed. Please verify the license plate and try again" />;
  }, [error]);

  const handleVerify = () => {
    if (!numberPlate || !state) return;

    verifyVehicle({
      id: vehicle.uuid,
      registration_number: numberPlate,
      state_uuid: state,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle verified successfully");
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
        setOpen(false);
      })
      .catch(() => {
        console.warn("Failed to verify vehicle");
      });
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} className="!max-w-[550px] !p-5">
      <div className="relative">
        {isLoading && <Loading />}
        <div className="mt-5 flex">
          <Typography variant="title" className="flex-1">
            Verify Vehicle
          </Typography>
          <div className="flex justify-end">
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={() => setOpen(false)} />
          </div>
        </div>

        <div className="flex max-w-lg flex-col">
          {renderAlert()}
          <form className="mt-6 flex flex-col gap-4">
            <label htmlFor="model-name" className="flex flex-col gap-1">
              <Typography variant="paragraph">Enter your {modelMake} plate number to verify</Typography>
              <TextInput
                placeholder="000-0000"
                id="number-plate"
                value={numberPlate}
                onChange={(e) => setNumberPlate(e.target.value)}
                required
                autoFocus
              />
            </label>
            <label htmlFor="state" className="flex flex-col gap-1">
              <Typography variant="paragraph">Select the State of your Vehicle</Typography>

              <DropdownSelect
                className="w-full"
                placeholder="Select State"
                options={options}
                value={state}
                onChange={setState}
                disabled={isEmpty(options) || isFetching}
              />
            </label>
          </form>
        </div>
        <div className="mt-6 ">
          <Button variant="primary" onClick={handleVerify} size="lg" className="w-full" disabled={isLoading}>
            {isLoading && <Spinner />}
            Verify for $5.00
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton, BooleanDisplay } from "@/components/atoms";
import { ConfigDetail } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { useGetDispatchSettingsQuery } from "@/redux/apis/config/admin/dispatchSettings";
import { useAppSelector } from "@/redux/hooks";
import { dispatchSettingsSelector } from "@/redux/slices/admin/selectors";
import { renderErrors } from "../../helpers";
import { DispatchSettingsEdit } from "./DispatchSettingsEdit";
import { renderDispatchTime } from "./helpers";

const renderRetryCount = (count: string) => {
  if (count) {
    return `${count} time${Number(count) !== 1 ? "s" : ""}`;
  }

  return null;
};

export const DispatchSettings = () => {
  const { isFetching } = useGetDispatchSettingsQuery();
  const { errors, details } = useAppSelector(dispatchSettingsSelector);
  const navigate = useNavigate();
  const { ref, entry, inView } = useInView({
    rootMargin: "-180px 0px 0px 0px",
  });

  const renderFleetDispatchRetryCounts = () => {
    if (Number(details.dispatchAutoOwnFleetRetryCount) > 0 || Number(details.dispatchAutoPrivateNetworkRetryCount) > 0) {
      return (
        <>
          <ConfigDetail
            title="Fleet Dispatch Retry Counts"
            description="When utilising automated dispatch, how many retries before moving on from our own fleet / private network? Note: this may delay speed to offload."
          />
          <ConfigDetail title="Own Fleet - Retry Count" indentPadding="pl-4" isBullet description="Number of times to attempt dispatch">
            {renderRetryCount(details.dispatchAutoOwnFleetRetryCount)}
          </ConfigDetail>
          <ConfigDetail title="Private Network - Retry Count" indentPadding="pl-4" isBullet description="Number of times to attempt dispatch">
            {renderRetryCount(details.dispatchAutoPrivateNetworkRetryCount)}
          </ConfigDetail>
        </>
      );
    }
  };

  if (isFetching) return <Skeleton />;

  return (
    <>
      <PageHeader.Actions>
        {entry && !inView && (
          <Button className="ml-auto animate-enter" startIcon="Edit2" onClick={() => navigate("edit")}>
            Edit
          </Button>
        )}
      </PageHeader.Actions>
      <Panel>
        <div className="flex items-center gap-4">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Dispatch Settings
            </Typography>
            <Typography className="text-neutral-dark-gray">Control the time when you want to dispatch a job for the Drivers.</Typography>
          </div>
          <div className="ml-auto" ref={ref}>
            <Button startIcon="Edit2" onClick={() => navigate("edit")}>
              Edit
            </Button>
          </div>
        </div>
        {renderErrors(errors)}

        <ConfigDetail
          title="Hold Jobs Before Dispatch"
          description="Define holding periods before automated dispatch starts after a job being received or edited"
        >
          <BooleanDisplay status={details.enabledHoldBeforeDispatch} />
        </ConfigDetail>
        <ConfigDetail title="Standard Hold Time" description="Time to hold standard jobs" indentPadding="pl-2">
          {renderDispatchTime(details.dispatchHoldBeforeDispatchTime, details.dispatchHoldBeforeDispatchTimeframe)}
        </ConfigDetail>
        <ConfigDetail title="ASAP Hold Time" description="Time to hold ASAP jobs" indentPadding="pl-2">
          {renderDispatchTime(details.dispatchAsapHoldBeforeDispatchTime, details.dispatchAsapHoldBeforeDispatchTimeframe)}
        </ConfigDetail>
        
        <ConfigDetail title="Automatic dispatch to own fleet" description="Automatic dispatch of jobs to own fleet enabled?">
          <BooleanDisplay status={details.enabledAutomaticDispatch} />
        </ConfigDetail>
        <ConfigDetail title="Dispatch Tier Times" description="Timing of when to dispatch to each tier." indentPadding="pl-2" />
        {/* <ConfigDetail title="Preferred Tier" description="Set when the system will start dispatching before pickup for any jobs  with preferred drivers available" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchTier0Time, details.dispatchTier0Timeframe)}
        </ConfigDetail> */}
        <ConfigDetail title="Tier 1" description="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchTier1Time, details.dispatchTier1Timeframe)}
        </ConfigDetail>
        <ConfigDetail title="Tier 2" description="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchTier2Time, details.dispatchTier2Timeframe)}
        </ConfigDetail>
        <ConfigDetail title="Tier 3" description="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchTier3Time, details.dispatchTier3Timeframe)}
        </ConfigDetail>
        <ConfigDetail title="Preferred Special Event Tier" description="Set when the system will start dispatching before pickup for any jobs  with preferred drivers available" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchSpecialEventTier0Time, details.dispatchSpecialEventTier0Timeframe)}
        </ConfigDetail>
        <ConfigDetail title="Special Event Tier 1" description="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchSpecialEventTier1Time, details.dispatchSpecialEventTier1Timeframe)}
        </ConfigDetail>
        <ConfigDetail title="Special Event Tier 2" description="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchSpecialEventTier2Time, details.dispatchSpecialEventTier2Timeframe)}
        </ConfigDetail>
        <ConfigDetail title="Special Event Tier 3" description="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchSpecialEventTier3Time, details.dispatchSpecialEventTier3Timeframe)}
        </ConfigDetail>

        <ConfigDetail title="Auto Dispatch to Private Network" description="Set when the system will start dispatching before pickup">
          <BooleanDisplay status={details.enabledAutomaticDispatchPrivateNetwork} />
        </ConfigDetail>
        <ConfigDetail title="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchAutoPrivateNetworkTime, details.dispatchAutoPrivateNetworkTimeframe)}
        </ConfigDetail>

        <ConfigDetail title="Auto Dispatch to Public Network" description="Set when the system will start dispatching before pickup">
          <BooleanDisplay status={details.enabledAutomaticDispatchPublicNetwork} />
        </ConfigDetail>
        <ConfigDetail title="Set when the system will start dispatching before pickup" indentPadding="pl-4" isBullet>
          {renderDispatchTime(details.dispatchAutoPublicNetworkTime, details.dispatchAutoPublicNetworkTimeframe)}
        </ConfigDetail>

        {renderFleetDispatchRetryCounts()}
      </Panel>
    </>
  );
};

DispatchSettings.Edit = DispatchSettingsEdit;

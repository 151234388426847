import { format } from "date-fns";
import { useState } from "react";
import { Button, Icon, Loading, Typography } from "@/components/atoms";
import { BookingFlight } from "@/redux/slices/booking/types";
import { clsx } from "@/utils";
import { BookingFlightStatusCode } from "../../common/BookingFlightStatusCode/BookingFlightStatusCode";
import { FlightTrackingDetailsModal } from "./components/FlightTrackingDetailsModal";
import { useOperator } from "@/hooks";
import { useGetPremiumAddOnsQuery } from "@/redux/apis/addOn/addOnApi";
import { FlightTrackingModal } from "@/components/organisms/AddOns";
import { useAppSelector } from "@/redux/hooks";
import { addOnItemSelector } from "@/redux/slices/addOn/selectors";

export interface BookingFlightTrackingProps extends React.ComponentProps<"div"> {
  flight: BookingFlight;
}

export const BookingFlightTracking = ({ flight, className, ...props }: BookingFlightTrackingProps) => {
  const { roles } = useOperator();
  const { isFetching } = useGetPremiumAddOnsQuery();
  const flightTracking = useAppSelector(addOnItemSelector("flight-tracking"));
  const isEnabled = flightTracking?.enabled;
  const isAdmin = roles.includes("Fa_Access") || roles.includes("Owner") || roles.includes("Admin");
  const [step, setStep] = useState<"details" | "add-on" | undefined>(undefined);
  const {
    number,
    status,
    delay,
    arrival: { terminal, gateEstimated, estimated, scheduled },
  } = flight;

  const displayTime = gateEstimated || estimated || scheduled;

  const hasData = status !== "not_found" && status !== "unknown" && status !== "scheduled";
  const statusClasses = {
    "is-enabled": isEnabled,
    "has-data": hasData || !isEnabled,
    "is-cancelled": status === "cancelled",
  };

  const mobileClassess = "max-xl:bg-neutral-gray max-xl:text-black max-xl:mt-3 max-xl:border-none";

  return (
    <>
      <div className="relative group/flight-unlock" onClick={() => isEnabled ? setStep("details") : null}>
        {isFetching && <Loading className="rounded-lg z-30" />}
        <div
          className={clsx(
            "group/flight inline-flex h-[63px] rounded-lg border border-neutral-dark-gray bg-white/30 p-2.5 text-white max-xl:self-start",
            { "cursor-pointer": isEnabled },
            statusClasses,
            mobileClassess,
            className
          )}
          {...props}
        >
          <div className="flex flex-col justify-center gap-1 border-white/50 pr-5 group-[.has-data]/flight:border-r group-[:not(.is-enabled)]/flight:border-r max-lg:border-neutral-dark-gray/50">
            <Typography className="flex items-center gap-1 text-sm font-semibold leading-relaxed">
              <Icon name="Airplane" variant="Bold" size="sm" />
              {number}
            </Typography>
            {isEnabled && <BookingFlightStatusCode status={status} delay={delay} type="vibrant" />}
          </div>
          <div className="relative flex flex-1 items-center justify-end overflow-hidden">
            <div className="left-0 ml-3 flex flex-col gap-1 group-hover/flight-unlock:group-[:not(.is-enabled)]/flight:absolute group-[:not(.is-enabled)]/flight:blur-[2px] group-[.has-data]/flight:block hidden">
              <Typography className="flex items-center gap-1 text-sm font-semibold group-[.is-cancelled]/flight:line-through">
                <Icon name="Clock" size="xs" /> {format(displayTime ? new Date(displayTime) : new Date(), "h:mm a")}
              </Typography>
              <Typography className="flex items-center gap-1 text-sm font-semibold group-[.is-cancelled]/flight:line-through">
                <Icon name="Location" size="xs" /> Terminal {terminal}
              </Typography>
            </div>
            {isEnabled ? <Icon className="ml-4 mr-2" name="ArrowRight2" size="lg" /> : (
              <Button
                className="ml-4 mr-2 relative z-20 inline-flex !h-[32px] min-w-[32px] justify-center gap-0 rounded-full border-neutral-black !bg-neutral-black !p-1.5 !opacity-100 transition-all group-hover/flight-unlock:gap-2 group-hover/flight-unlock:!px-[15px]"
                startIcon={<Icon name="diamond" isCustom size="sm" />}
                variant="custom"
                onClick={() => setStep("add-on")}
              >
                <span className="hidden group-hover/flight-unlock:block text-white">Enable Flight Tracking</span>
              </Button>
            )}
          </div>
        </div>
      </div>
      <FlightTrackingDetailsModal 
        open={step === "details"} 
        flight={flight} 
        onClose={() => setStep(undefined)} 
      />
      <FlightTrackingModal 
        open={step === "add-on"} 
        onClose={() => setStep(undefined)} 
        isAdmin={isAdmin}
      />
    </>
  );
};

import { differenceInWeeks } from "date-fns";
import { Icon } from "@/components/atoms";
import { Vehicle } from "@/redux/slices/vehicle/types";

export const VehicleRegistrationStatusBadge = ({ vehicle }: { vehicle: Vehicle }) => {
  const { metadata } = vehicle;
  const expiringInWeeks = metadata.verifiedExpiry ? differenceInWeeks(new Date(metadata.verifiedExpiry), new Date()) : undefined;

  const status = expiringInWeeks
    ? expiringInWeeks > 6
      ? "success"
      : expiringInWeeks <= 6 && expiringInWeeks > 0
      ? "warning"
      : "danger"
    : "default";

  switch (status) {
    case "success":
      return <Icon name="Verify" className="text-info" variant="Bold" size="md" />;
    case "warning":
      return <Icon name="Danger" size="md" className="text-warning" variant="Bold" />;
    case "danger":
      return <Icon name="Danger" size="md" className="text-danger" variant="Bold" />;

    case "default":
      return null;
  }
};

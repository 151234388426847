import { DataTableColumnDef } from "@/components/molecules";
import { MyVehicleTableData } from "./types";

export const myVehiclesColumns: DataTableColumnDef<MyVehicleTableData>[] = [
  {
    header: "Year",
    id: "year",
    accessorKey: "year",
    size: 100,
  },
  {
    header: "Make",
    id: "make",
    cell: (props) => props.row.original.make,
    accessorKey: "make",
    size: 150,
  },
  {
    header: "Model",
    id: "model",
    accessorKey: "model",
    size: 150,
  },
  {
    header: "Number Plate",
    id: "numberPlate",
    accessorKey: "numberPlate",
    cell: (props) => props.row.original.numberPlate,
    size: 150,
  },
  {
    header: "Nickname",
    size: 150,
    id: "identifier",
    accessorKey: "nickname",
  },
  {
    header: "Color",
    id: "color",
    cell: (props) => props.row.original.color,
    size: 150,
  },
  {
    header: "Current Driver",
    id: "driver",
    cell: (props) => props.row.original.driver,
    size: 150,
  },
  {
    header: "Actions",
    id: "actions",
    cell: (props) => props.row.original.actions,
    maxSize: 75,
  },
];

import { isEmpty } from "ramda";
import { useCallback, useMemo, useRef, useState } from "react";
import { Alert, Button, DropdownSelect, Icon, Loading, Modal, Spinner, TextInput, Typography } from "@/components/atoms";
import { getErrorData, getErrorMessages, isErrorResponse } from "@/helpers/reduxHelpers";
import { useOperator } from "@/hooks";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useVerifyVehicleMutation, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { useAppDispatch } from "@/redux/hooks";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { RawErrorResponse } from "@/redux/types";
import { addToast, clsx } from "@/utils";
import { ConfirmRemoveVerificationModal } from "./ConfirmRemoveVerificationModal";

interface UpdateVerificationModalProps extends React.HTMLAttributes<HTMLDivElement> {
  vehicle: Vehicle;
  open: boolean;
  onClose: () => void;
}

export const UpdateVerificationModal = ({ vehicle, open, onClose, ...props }: UpdateVerificationModalProps) => {
  const dispatch = useAppDispatch();
  const [showConfirmRemoveVerification, setShowConfirmRemoveVerification] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { country } = useOperator();
  const { isFetching, data: response } = useGetCountryStatesQuery(country);
  const [verifyVehicle, { isLoading, error }] = useVerifyVehicleMutation();

  const [numberPlate, setNumberPlate] = useState<string>(vehicle.registrationNumber);
  const [state, setState] = useState<string>(vehicle.state.uuid);

  const modelMake = `${vehicle.model.make} ${vehicle.model.model}`;

  const options = useMemo(
    () => response?.data.states.map((state) => ({ name: state.name, value: state.id })) ?? [],
    [response?.data.states]
  );

  const errorType = useMemo(() => {
    if (!error || !isErrorResponse(error)) return undefined;

    const messages = getErrorMessages(error || {}).join(", ");
    if (messages.includes("Vehicle verification failed")) return "verification-failed";

    const errorData = getErrorData(error as RawErrorResponse);
    if (!errorData) return "verification-failed";

    const { error: msg, data } = errorData;

    if (msg === "VEHICLE_EXISTS" && data?.is_archived) return `vehicle-archived-${data.vehicle_uuid}`;
    else if (msg) return "vehicle-exists";
    else return "verification-failed";
  }, [error]);

  const renderAlert = useCallback(() => {
    return !!error && <Alert className="mt-4" type="danger" message="Verification failed. Please verify the license plate and try again" />;
  }, [error]);

  const handleVerify = () => {
    if (!numberPlate || !state) return;

    verifyVehicle({
      id: vehicle.uuid,
      registration_number: numberPlate,
      state_uuid: state,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle verified successfully");
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
        onClose();
      })
      .catch(() => {
        console.warn("Failed to verify vehicle");
      });
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="!max-w-[550px] !p-5">
        <div className={clsx("relative")} {...props}>
          {isLoading && <Loading />}

          <div className="mt-5 flex">
            <Typography variant="title" className="flex-1">
              Update Vehicle Verificaton
            </Typography>
            <div className="flex justify-end">
              <Icon name="close" isCustom className="flex cursor-pointer" onClick={onClose} />
            </div>
          </div>
          <Typography variant="paragraph" className="mt-2 text-neutral-dark-gray">
            To update the verified details, simply re-verify this vehicle by confirming the plate number and state.
          </Typography>
          <div className="flex max-w-lg flex-col">
            {renderAlert()}

            <form className="mt-6 flex flex-col gap-4">
              <label htmlFor="model-name" className="flex flex-col gap-1">
                <Typography variant="paragraph">Enter your {modelMake} plate number to verify</Typography>

                <TextInput
                  placeholder="000-0000"
                  id="number-plate"
                  value={numberPlate}
                  onChange={(e) => setNumberPlate(e.target.value)}
                  hasError={errorType === "verification-failed"}
                  hasWarning={errorType === "vehicle-exists"}
                  required
                  autoFocus
                  ref={inputRef}
                />
              </label>
              <label htmlFor="state" className="flex flex-col gap-1">
                <Typography variant="paragraph">Select the State of your Vehicle</Typography>

                <DropdownSelect
                  className="w-full"
                  placeholder="Select State"
                  options={options}
                  value={state}
                  onChange={setState}
                  disabled={isEmpty(options) || isFetching}
                />
              </label>
            </form>
          </div>
          <div className="mt-6 space-y-2 ">
            <Button variant="primary" onClick={handleVerify} size="lg" className="w-full" disabled={isLoading}>
              {isLoading && <Spinner />}
              Reverify for $5.00
            </Button>
            <Button variant="secondary" onClick={() => setShowConfirmRemoveVerification(true)} size="lg" className="w-full">
              Remove Verification
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmRemoveVerificationModal open={showConfirmRemoveVerification} onClose={() => setShowConfirmRemoveVerification(false)} />
    </>
  );
};

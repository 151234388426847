import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, ErrorMessage, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { VehicleDocumentDateInput } from "../common/VehicleDocumentDateInput";
import { VehicleDocumentUploadField } from "../common/VehicleDocumentUpload";
import { useEditVehicleContext } from "../context";
import { EditUnverifiedVehicleFormData } from "../form";
import { useVehicleDocuments } from "../hooks/useVehicleDocuments";

export const VehicleDocuments = () => {
  const { dateFormat } = useProfile();
  const { vehicle, setDocumentRetryCount } = useEditVehicleContext();

  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<EditUnverifiedVehicleFormData>();

  const { accreditationDoc, insuranceDoc, registrationDoc } = watch("documents");

  const documents = useVehicleDocuments({
    vehicleId: vehicle.uuid,
    insuranceId: insuranceDoc ?? undefined,
    registrationId: registrationDoc ?? undefined,
    accreditationId: accreditationDoc ?? undefined,
  });

  useEffect(() => {
    setValue("documents.registrationAutoverified", !documents.registration.manualExpiryDate);
    setValue("documents.insuranceAutoverified", !documents.insurance.manualExpiryDate);
    setValue("documents.accreditationAutoverified", !documents.accreditation.manualExpiryDate);
  }, [documents.accreditation.manualExpiryDate, documents.insurance.manualExpiryDate, documents.registration.manualExpiryDate, setValue]);

  const isVerified = vehicle.metadata.isVerified;

  return (
    <>
      {!isVerified && (
        <>
          <label htmlFor="documents.registrationDoc" className="flex flex-col">
            <Typography className="leading-[2rem]">Vehicle Registration Document</Typography>
            <Controller
              control={control}
              name="documents.registrationDoc"
              render={({ field }) => (
                <VehicleDocumentUploadField
                  document={documents.registration.document}
                  documentType="REGISTRATION"
                  onUpload={(uuid) => {
                    field.onChange(uuid);
                    setDocumentRetryCount((prev) => ({ ...prev, registration: 0 }));
                  }}
                  onRemove={() => {
                    field.onChange(null);
                    setDocumentRetryCount((prev) => ({ ...prev, registration: 0 }));
                  }}
                  hasError={!!errors.documents?.registrationDoc}
                />
              )}
            />
            {errors.documents?.registrationDoc?.message ? (
              <ErrorMessage
                className="mt-2 text-sm"
                name="documents"
                errors={errors}
                message={errors.documents?.registrationDoc?.message}
              />
            ) : (
              <Typography className="mt-2 text-sm text-danger empty:hidden">{documents.registration.errorMessage}</Typography>
            )}
          </label>
          {documents.registration.document && !documents.registration.isLoading ? (
            <label htmlFor="documents.registrationExpiry">
              <Typography className="leading-[2rem]">Registration Expiry Date</Typography>
              <Controller
                name="documents.registrationExpiry"
                control={control}
                render={({ field }) => {
                  const selected =
                    !documents.registration.manualExpiryDate && documents.registration.document?.expiryDate
                      ? new Date(documents.registration.document.expiryDate)
                      : field.value;

                  return (
                    <VehicleDocumentDateInput
                      className="w-full"
                      onChange={(date) => field.onChange(date)}
                      selected={selected}
                      placeholderText="-- / -- / ----"
                      hasError={!!errors.documents?.registrationExpiry}
                      dateFormat={dateFormat}
                      disabled={!documents.registration.manualExpiryDate}
                    />
                  );
                }}
              />
              {documents.registration.manualExpiryDate && (
                <div className="mt-2">
                  <CheckboxInput
                    {...register("documents.registrationExpiryConfirm")}
                    id="confirm-registration"
                    label="I confirm that I am responsible for the date entered"
                    className="text-neutral-dark-gray [&_.typography]:!text-sm"
                    variant="check"
                  />
                </div>
              )}
              {errors.documents?.registrationExpiry?.message && (
                <ErrorMessage className="text-sm" name="documents" errors={errors} message={errors.documents.registrationExpiry.message} />
              )}
            </label>
          ) : (
            <label></label>
          )}
        </>
      )}

      <label htmlFor="documents.insuranceDoc" className="flex flex-col">
        <Typography className="leading-[2rem]">Vehicle Insurance Document</Typography>
        <Controller
          control={control}
          name="documents.insuranceDoc"
          render={({ field }) => (
            <VehicleDocumentUploadField
              document={documents.insurance.document}
              documentType="INSURANCE"
              onUpload={(uuid) => {
                field.onChange(uuid);
                setDocumentRetryCount((prev) => ({ ...prev, insurance: 0 }));
              }}
              onRemove={() => {
                field.onChange(null);
                setDocumentRetryCount((prev) => ({ ...prev, insurance: 0 }));
              }}
              hasError={!!errors.documents?.insuranceDoc}
            />
          )}
        />
        {errors.documents?.insuranceDoc?.message ? (
          <ErrorMessage className="mt-2 text-sm" name="documents" errors={errors} message={errors.documents?.insuranceDoc?.message} />
        ) : (
          <Typography className="mt-2 text-sm text-danger empty:hidden">{documents.insurance.errorMessage}</Typography>
        )}
      </label>
      {documents.insurance.document && !documents.insurance.isLoading ? (
        <label htmlFor="documents.insuranceExpiry">
          <Typography className="leading-[2rem]">Insurance Expiry Date</Typography>
          <Controller
            name="documents.insuranceExpiry"
            control={control}
            render={({ field }) => {
              const selected =
                !documents.insurance.manualExpiryDate && documents.insurance.document?.expiryDate
                  ? new Date(documents.insurance.document.expiryDate)
                  : field.value;

              return (
                <VehicleDocumentDateInput
                  className="w-full"
                  onChange={(date) => field.onChange(date)}
                  selected={selected}
                  placeholderText="-- / -- / ----"
                  hasError={!!errors.documents?.insuranceExpiry}
                  dateFormat={dateFormat}
                  disabled={!documents.insurance.manualExpiryDate}
                />
              );
            }}
          />
          {documents.insurance.manualExpiryDate && (
            <div className="mt-2">
              <CheckboxInput
                {...register("documents.insuranceExpiryConfirm")}
                id="confirm-insurance"
                label="I confirm that I am responsible for the date entered"
                className="text-neutral-dark-gray [&_.typography]:!text-sm"
                variant="check"
              />
            </div>
          )}
          {errors.documents?.insuranceExpiry?.message && (
            <ErrorMessage className="text-sm" name="documents" errors={errors} message={errors.documents.insuranceExpiry.message} />
          )}
        </label>
      ) : (
        <label></label>
      )}

      <label htmlFor="documents.accreditationDoc" className="flex flex-col">
        <Typography className="leading-[2rem]">Vehicle Accreditation Document (Optional)</Typography>
        <Controller
          control={control}
          name="documents.accreditationDoc"
          render={({ field }) => (
            <VehicleDocumentUploadField
              document={documents.accreditation.document}
              documentType="ACCREDITATION"
              onUpload={(uuid) => {
                field.onChange(uuid);
                setDocumentRetryCount((prev) => ({ ...prev, accreditation: 0 }));
              }}
              onRemove={() => {
                field.onChange(null);
                setDocumentRetryCount((prev) => ({ ...prev, accreditation: 0 }));
              }}
              hasError={!!errors.documents?.accreditationDoc}
            />
          )}
        />
        {errors.documents?.accreditationDoc?.message ? (
          <ErrorMessage className="mt-2 text-sm" name="documents" errors={errors} message={errors.documents?.accreditationDoc?.message} />
        ) : (
          <Typography className="mt-2 text-sm text-danger empty:hidden">{documents.accreditation.errorMessage}</Typography>
        )}
      </label>
      {documents.accreditation.document && !documents.accreditation.isLoading ? (
        <label htmlFor="documents.accreditationExpiry">
          <Typography className="leading-[2rem]">Accreditation Expiry Date (Optional)</Typography>
          <Controller
            name="documents.accreditationExpiry"
            control={control}
            render={({ field }) => {
              const selected =
                !documents.accreditation.manualExpiryDate && documents.accreditation.document?.expiryDate
                  ? new Date(documents.accreditation.document.expiryDate)
                  : field.value;

              return (
                <VehicleDocumentDateInput
                  className="w-full"
                  onChange={(date) => field.onChange(date)}
                  selected={selected}
                  placeholderText="-- / -- / ----"
                  hasError={!!errors.documents?.accreditationExpiry}
                  dateFormat={dateFormat}
                  disabled={!documents.accreditation.manualExpiryDate}
                />
              );
            }}
          />
          {documents.accreditation.manualExpiryDate && (
            <div className="mt-2">
              <CheckboxInput
                {...register("documents.accreditationExpiryConfirm")}
                id="confirm-accreditation"
                label="I confirm that I am responsible for the date entered"
                className="text-neutral-dark-gray [&_.typography]:!text-sm"
                variant="check"
              />
            </div>
          )}
          {errors.documents?.accreditationExpiry?.message && (
            <ErrorMessage className="text-sm" name="documents" errors={errors} message={errors.documents?.accreditationExpiry.message} />
          )}
        </label>
      ) : (
        <label></label>
      )}
    </>
  );
};

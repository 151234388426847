import { Dropzone, Icon, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddVehicleDocumentMutation, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { useAppDispatch } from "@/redux/hooks";
import { addToast } from "@/utils";
import { useViewVehicleContext } from "../../context";

export const UploadVehicleDocument = () => {
  const dispatch = useAppDispatch();
  const { vehicle } = useViewVehicleContext();
  const [addVehicleDocument, { isLoading }] = useAddVehicleDocumentMutation();

  const onUpload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("document_type", "OTHER");
    formData.append("is_attached", "1");

    addVehicleDocument({ id: vehicle.uuid, body: formData })
      .unwrap()
      .then(() => {
        addToast("success", "Document uploaded successfully");
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
      })
      .catch((e) => {
        getErrorMessages(e).forEach((message) => addToast("danger", message));
      });
  };

  return (
    <Dropzone
      accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "application/pdf": [".pdf"] }}
      maxSize={10000000}
      upload={onUpload}
      disabled={isLoading}
      className="relative flex min-h-[200px] w-[170px]  rounded-lg border border-dashed border-neutral-mid-gray bg-neutral-gray text-neutral-dark-gray hover:bg-neutral-surface-gray"
      detail={
        <div className="flex cursor-pointer flex-col place-content-center place-items-center gap-4">
          {isLoading && <Loading />}
          <Icon name="Add" size={48} />
          <Typography variant="paragraph" className="text-center">
            Upload Vehicle Document
          </Typography>
        </div>
      }
    />
  );
};

import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Button, Loading, SearchInput, Typography } from "@/components/atoms";
import { useSearchVehicleMakeQuery } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { clsx, getAssetUrl } from "@/utils";
import { VehicleMakeButton } from "./common/VehicleMakeButton";
import { useAddVehicleContext } from "./context";
import { FormData } from "./form";

export const SelectMake = () => {
  const { setStep } = useAddVehicleContext();
  const { setValue, watch } = useFormContext<FormData>();
  const type = watch("type");
  const [search, setSearch] = useState<string | undefined>("");
  const [enableSearch, setEnableSearch] = useState(false);
  const { data: response, isLoading } = useSearchVehicleMakeQuery({ q: search || "", type: type.toUpperCase() }, { skip: !type });

  const makes = useMemo(() => {
    if (!response) return [];

    return response.makes;
  }, [response]);

  const selectVehicleMake = (make: string, avatar: string) => {
    setValue("make", {
      name: make,
      avatar: avatar,
    });
    setStep("select-model");
  };

  const onBlur = () => {
    if (!search) {
      setEnableSearch(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <Typography variant="title" className="leading-8">
        Select Make
      </Typography>
      {enableSearch && (
        <motion.div
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeOut", duration: 0.5 }}
          className={clsx(" w-[500px] items-center rounded-lg p-2")}
        >
          <SearchInput
            placeholder="Search Make"
            onBlur={onBlur}
            onClear={() => setEnableSearch(false)}
            onSearch={setSearch}
            className="w-full"
            autoFocus
          />
        </motion.div>
      )}
      <div className="thin-scrollbar relative mt-5 h-[450px] w-[500px] overflow-y-auto ">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {enableSearch && search && <Typography className="text-center">{makes.length > 0 ? "Results:" : "No make found"}</Typography>}
            <div className="inline-grid grid-cols-3 justify-center gap-5 p-2">
              {makes.map((make, idx) => (
                <VehicleMakeButton
                  key={idx}
                  src={getAssetUrl(make.avatar, { height: 112, width: 112, fit: "contain", background: "transparent" })}
                  name={make.name}
                  onClick={() => selectVehicleMake(make.name, make.avatar)}
                />
              ))}
            </div>
            <div className="pointer-events-none sticky bottom-0 -mt-[130px] h-[130px] w-full bg-gradient-to-b from-transparent via-transparent to-[#FAFAFA]" />
          </>
        )}
      </div>
      {!enableSearch && (
        <div className="mt-12 flex justify-center">
          <Button
            variant="tertiary"
            size="sm"
            startIcon="SearchNormal1"
            onClick={() => {
              setEnableSearch(true);
            }}
          >
            Search Make
          </Button>
        </div>
      )}
    </div>
  );
};

import { useMemo } from "react";
import { Icon, IconButton } from "@/components/atoms";
import { useGetVehicleAccessQuery } from "@/redux/apis/vehicle/vehicleApi";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const VehicleAccessDetailCard = () => {
  const {
    vehicle: { uuid, accessType },
    setActiveModal,
  } = useViewVehicleContext();

  const { data: response, isFetching } = useGetVehicleAccessQuery(uuid);

  const access = useMemo(() => {
    if (!response) return [];

    return response;
  }, [response]);

  const handleClick = () => {
    setActiveModal("access-manager");
  };

  const ActionButton = () => {
    //todo: replace with actual action button
    return (
      <IconButton iconName="ArrowRight2" className="p-1.5 text-neutral-black" iconSize="lg" variant="tertiary" onClick={handleClick} />
    );
  };

  return (
    <VehicleDetailCard
      icon={<Icon name="People" size="xl" className="text-neutral-black" />}
      description={`${isFetching ? "-" : access.length} ${accessType !== "ALL" ? "(Restricted Access)" : ""}`}
      title="People with access"
      action={<ActionButton />}
    />
  );
};
